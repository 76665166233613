<template>
  <div class="KCmodule">
    <Header title="专利费用" index="首页" titleOne="工程专利" beforeTitle="专利费用" />
    <div class="content">
      <div class="checkbox">
        <el-checkbox-group v-model="checkList" @change="check_click">
          <el-checkbox label="1" border>申请费</el-checkbox>
          <el-checkbox label="2" border>公布印刷费</el-checkbox>
          <el-checkbox label="3" border>实质审查费</el-checkbox>
          <el-checkbox label="4" border>著录事项变更费</el-checkbox>
          <el-checkbox label="5" border>其他费</el-checkbox>
          <el-checkbox label="6" border>滞纳金</el-checkbox>
          <el-checkbox label="7" border>恢复权利请求费</el-checkbox>
          <el-checkbox label="8" border>专利权评价报告请求费</el-checkbox>
          <el-checkbox label="9" border>专利权无效宣告请求费</el-checkbox>
          <el-checkbox label="10" border>复审费</el-checkbox>
          <el-checkbox label="11" border>说明书附加费</el-checkbox>
          <el-checkbox label="12" border>权利要求附加费</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="topUsers">
        <div class="top_l">
          <el-select
            clearable
            v-model="patprojectParams.patent_type"
            placeholder="请选择案卷类型"
            @change="changePatentType"
          >
            <el-option label="发明专利" value="1">发明专利</el-option>
            <el-option label="实用新型" value="2">实用新型</el-option>
            <el-option label="外观设计" value="3">外观设计</el-option>
            <el-option label="植物新品种" value="4">植物新品种</el-option>
            <el-option label="国家新药" value="5">国家新药</el-option>
            <el-option label="集成电路布图设计专有权" value="6">集成电路布图设计专有权</el-option>
            <el-option label="国家级农作物品种" value="7">国家级农作物品种</el-option>
            <el-option label="国家一级中药保护品种" value="8">国家一级中药保护品种</el-option>
            <el-option label="其中：国防专利" value="9">其中：国防专利</el-option>
          </el-select>
          <el-select
            clearable
            v-model="patprojectParams.is_expire"
            placeholder="请选择是否过期"
            @change="changePatentType"
          >
            <el-option label="否" value="0">否</el-option>
            <el-option label="是" value="1">是</el-option>
          </el-select>
          <el-select
            clearable
            v-model="patprojectParams.surplus_days"
            placeholder="请选择剩余天数"
            @change="changePatentDays"
          >
            <el-option label="7天" value="7"></el-option>
            <el-option label="15天" value="15"></el-option>
            <el-option label="30天" value="30"></el-option>
            <el-option label="60天" value="60"></el-option>
            <el-option label="180天" value="180"></el-option>
          </el-select>
          <el-select
            clearable
            v-model="patprojectParams.status"
            placeholder="请选择状态"
            @change="changePatentType"
          >
            <el-option label="不提醒" value="0"></el-option>
            <el-option label="提醒" value="1"></el-option>
            <el-option label="滞纳" value="2"></el-option>
          </el-select>
          <el-select
            clearable
            v-model="patprojectParams.patent_status"
            placeholder="请选择案卷状态"
            @change="changeStatus"
          >
            <el-option label="新申请" value="1">新申请</el-option>
            <el-option label="审查中" value="2">审查中</el-option>
            <el-option label="审查合格" value="3">审查合格</el-option>
            <el-option label="发明实审" value="4">发明实审</el-option>
            <el-option label="授权" value="5">授权</el-option>
            <el-option label="驳回" value="6">驳回</el-option>
            <el-option label="撤回" value="7">撤回</el-option>
            <el-option label="专利权维持" value="8">专利权维持</el-option>
            <el-option label="专利权失效" value="9">专利权失效</el-option>
          </el-select>
          <el-select
            clearable
            v-model="patprojectParams.is_monitor"
            placeholder="请选择是否监控"
            @change="changePatentType"
          >
            <el-option label="否" value="0">否</el-option>
            <el-option label="是" value="1">是</el-option>
          </el-select>
          <el-date-picker
            v-model="patprojectParams.app_date"
            type="daterange"
            range-separator="至"
            start-placeholder="申请开始日期"
            end-placeholder="申请结束日期"
            @change="chooseTimeRange1"
          ></el-date-picker>
          <el-date-picker
            v-model="patprojectParams.certificate_date"
            type="daterange"
            range-separator="至"
            start-placeholder="下证开始日期"
            end-placeholder="下证结束日期"
            @change="chooseTimeRange2"
          ></el-date-picker>
          <div class="tabs" style="min-width: 900px;display: inline-block;">
            <el-select
              style="width: 100% !important;"
              multiple
              clearable
              v-model="patprojectParams.tag"
              placeholder="请选择标签"
              @change="changeTag"
            >
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item,index) in options"
                :key="index"
              ></el-option>
            </el-select>
          </div>
          <el-input
            clearable
            v-model="patprojectParams.company_name"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.app_code"
            placeholder="请输入申请号"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.internal_code"
            placeholder="请输入内部编号"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            clearable
            v-model="patprojectParams.applicant"
            placeholder="请输入申请人"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
          <!-- <el-button disabled @click="exportAll()" class="import">全部导出</el-button> -->
        </div>
      </div>
      <!-- <keep-alive> -->
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="patproject.data"
        style="width: 100%"
      >
        <el-table-column prop="is_monitor" label="是否监控" :show-overflow-tooltip="true" width="80">
          <template slot-scope="scope">
            <span v-if="!scope.row.is_monitor">否</span>
            <span v-if="scope.row.is_monitor">是</span>
          </template>
        </el-table-column>
        <el-table-column prop="patent_type" label="案卷类型" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.patent_type ? scope.row.patent_type : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="app_code" label="申请号" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.app_code ? scope.row.app_code : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="patent_name" label="专利名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.patent_name ? scope.row.patent_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="tag" label="标签">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Bottom Center 提示文字" placement="bottom">
              <template slot="content">
                <div class="tooltip-content">
                  <span style="color: #A86201;" v-if="scope.row.tag.includes('系统重复提交')">
                    系统重复提交
                    <br />
                  </span>
                  <span style="color: #8408FF;" v-if="scope.row.tag.includes('专利转让/变更')">
                    专利转让/变更
                    <br />
                  </span>
                  <span style="color: #1CC2FF;" v-if="scope.row.tag.includes('非正常申请重写')">
                    非正常申请重写
                    <br />
                  </span>
                  <span style="color: #03B262;" v-if="scope.row.tag.includes('非正常申请撤回')">
                    非正常申请撤回
                    <br />
                  </span>
                  <span style="color: #FFA01D;" v-if="scope.row.tag.includes('退费')">
                    退费
                    <br />
                  </span>
                  <span style="color: #FF2255;" v-if="scope.row.tag.includes('驳回/撤回重写')">
                    驳回/撤回重写
                    <br />
                  </span>
                  <span style="color: #ADB1B4;" v-if="scope.row.tag.includes('假专利')">
                    假专利
                    <br />
                  </span>
                  <span style="color: #F74691;" v-if="scope.row.tag.includes('代提交')">
                    代提交
                    <br />
                  </span>
                </div>
              </template>
              <span v-if="!scope.row.tag">-</span>
              <span style="color: #A86201;" v-if="scope.row.tag.includes('系统重复提交')">系统重复提交</span>
              <span style="color: #8408FF;" v-else-if="scope.row.tag.includes('专利转让/变更')">专利转让/变更</span>
              <span style="color: #1CC2FF;" v-else-if="scope.row.tag.includes('非正常申请重写')">非正常申请重写</span>
              <span style="color: #03B262;" v-else-if="scope.row.tag.includes('非正常申请撤回')">非正常申请撤回</span>
              <span style="color: #FFA01D;" v-else-if="scope.row.tag.includes('退费')">退费</span>
              <span style="color: #FF2255;" v-else-if="scope.row.tag.includes('驳回/撤回重写')">驳回/撤回重写</span>
              <span style="color: #ADB1B4;" v-else-if="scope.row.tag.includes('假专利')">假专利</span>
              <span style="color: #F74691;" v-else-if="scope.row.tag.includes('代提交')">代提交</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="inventor" label="第一申请人/发明人" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.inventor ? scope.row.inventor : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="fee_type" label="费用种类" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.fee_type ? scope.row.fee_type : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="payable" label="应缴（元）" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.payable ? scope.row.payable : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="end_date" label="缴费截止日期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.end_date ? scope.row.end_date : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="patent_status" label="案卷状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.patent_status ? scope.row.patent_status : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.status ? scope.row.status : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="surplus_days" label="剩余天数" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.surplus_days > 0 ? scope.row.surplus_days : "超时"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="is_expire" label="是否过期" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="!scope.row.is_expire">否</span>
            <span v-if="scope.row.is_expire">是</span>
          </template>
        </el-table-column>

        <el-table-column prop="operation" label="操作" width="150px">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="patentEdit(scope.row.patent_id)">编辑</el-button>
            <el-button class="del" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- </keep-alive> -->
      <div class="block" v-if="patproject.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="patprojectParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="patprojectParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="patproject.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  data () {
    return {
      options: [
        {
          name: '系统重复提交',
          id: '1'
        },

        {
          name: '专利转让/变更',
          id: '2'
        },

        {
          name: '非正常申请重写',
          id: '3'
        },
        {
          name: '非正常申请撤回',
          id: '4'
        },
        {
          name: '退费',
          id: '5'
        },
        {
          name: '驳回/撤回重写',
          id: '6'
        },
        {
          name: '假专利',
          id: '7'
        },
        {
          name: '代提交',
          id: '8'
        }
      ],
      checkList: [],//复选框内容
      checked2: '',
      loading: true,
      patprojectParams: {
        page: 1,
        limit: 10,
        keywords: "",
        patent_name: "",
        patent_type: "",
        status: "",
        company_name: "",
        order: "id desc",
      },
      privilige_list: {},
      patproject: [],
    };
  },
  activated () {
    this.patprojectParams
    // this.$store.dispatch("getpatproList", this.patprojectParams);
  },
  created () {
    let arr = localStorage.getItem('privilige')
    this.privilige_list = JSON.parse(arr)
    let status = localStorage.getItem('patent_header_status')
    if (JSON.parse(status)) {
      this.headerStatus = JSON.parse(status)
    }

    if (this.$route.query.days) {
      this.$set(this.patprojectParams, 'surplus_days', this.$route.query.days)
      // this.patprojectParams.surplus_days = this.$route.query.days
      this.getList()
    } else {
      this.getList()
    }
  },
  methods: {
    changeTag (x) {
      this.loading = true
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, page: '', tag: x.join() } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    check_click (a) {
      this.loading = true
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, page: '', fee_type: a } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
      console.log(this.checkList, this.patprojectParams)
    },
    patentEdit (patent_id) {
      this.$router.push({ path: '/patent/patent_edit', query: { id: patent_id, title: '专利费用', type: 'patent_cost' } })
    },
    getList () {
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    // exportAll () {
    //   // this.$router.push('')
    //   this.$router.push({
    //     path: "/data/data_export/go_export",
    //     query: { item: "project_patent" },
    //   });
    // },
    chooseTimeRange1 (t) {
      console.log(t);
      this.patprojectParams.start_time = t[0]
      this.patprojectParams.end_time = t[1]
      this.loading = true
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    chooseTimeRange2 (t) {
      this.patprojectParams.start_certificate_time = t[0]
      this.patprojectParams.end_certificate_time = t[1]
      this.loading = true
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
      // this.$store.dispatch("getpatproList", this.patprojectParams);
      this.getList()
    },
    handleSizeChange (val) {
      this.patprojectParams.limit = val;
      this.loading = true
      // this.$store.dispatch("getpatproList", this.patprojectParams);
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    handleCurrentChange (val) {
      this.patprojectParams.page = val;
      this.loading = true
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, keywords: '' });
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, keywords: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        setTimeout(() => {
          this.loading = false
        }, 100)
      })
    },
    changePatentType () {
      this.loading = true
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    changePatentDays (x) {
      this.patprojectParams.surplus_days = x
      this.loading = true
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    changeStatus () {
      this.loading = true
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    handleSearch () {
      // this.$store.dispatch("getpatproList", { ...this.patprojectParams, page: '', limit: '' });
      this.loading = true
      this.axios.get('/api/patent_fee/list', { params: { ...this.patprojectParams, page: '' } }).then((res) => {
        if (res.data) {
          this.patproject = res.data
        } else {
          this.patproject = []
        }
        this.loading = false
      })
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/patent_fee/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getList()
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
  </script>
  
  <style scoped="scoped">
.KCmodule .import {
  float: right;
  background: #edeef4;
  color: #909399;
  border: none;
}

::v-deep .el-tabs__content {
  top: 20px !important;
}

::v-deep .topUsers .el-input {
  width: 224px !important;
}
div/deep/.tabs .el-input {
  min-width: 900px !important;
}
.el-date-editor--daterange.el-input__inner {
  width: 270px;
  margin-right: 20px;
}
div/deep/.el-table_1_column_22 {
  padding-left: 20px !important;
}
div/deep/.el-table__body-wrapper {
  overflow-x: scroll !important;
}
div/deep/.el-table__body {
  margin-bottom: 10px;
}
/* .el-checkbox {
  display: block;
  margin-top: 10px;
} */
.el-select {
  margin-bottom: 15px;
}

div/deep/.el-checkbox {
  /* display: inline-block;
  width: 87px;*/
  height: 28px;
  padding: 5px 4px;
  margin-right: 0;
  margin-bottom: 20px;
}
div/deep/.el-checkbox__label {
  font-size: 14px;
  color: #606266;
}
.checkbox {
  display: flex;
  flex-wrap: wrap;
  /* margin-bottom: 20px; */
}
</style>
  